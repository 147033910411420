import React, { useEffect, useRef, useState } from "react";
import styled from 'styled-components';
import { Modal } from 'bootstrap/dist/js/bootstrap';
import { default as ClockingsService } from 'services/Clockings';
import { loader } from 'helpers/generic';
import { RelocationsTable } from "./RelocationsHistory";

const ModalStyled = styled.div`
	background: rgba(0, 0, 0, 0.4);

	&.modal {

        .scrollable {
            max-height: 350px;
            overflow-y: auto;

            #page-trigger {
                height: 10px;
                width: 100%;
            }
        }

        table {
            th, td {
                font-size: 12px !important;
            }
        }
	}
`;

const RelocationsHistoryModal = (props) => {
	const modalRef = useRef(null);
	const scrollableRef = useRef(null);
	const lastPageRef = useRef(null);

    const dispenserId = props.dispenser?.id;
	const closeCallback = props.closeCallback;

    const [loading, setLoading] = useState(false);
    const [relocations, setRelocations] = useState([]);
    const [page, setPage] = useState(1);

	useEffect(() => {
		const modal = new Modal(modalRef.current, {backdrop: false});

		const hiddenEvent = (e) => {
           	modal._element.removeEventListener('hidden.bs.modal', hiddenEvent);
           	modal._element.removeEventListener('shown.bs.modal', hiddenEvent);
			modal.dispose();
			closeCallback();

			// Fix, because bootstrap removes scroll and add padding on modal opened
			document.body.style.overflow = 'auto'; 
			document.body.style.paddingRight = '0';
		}

		modal._element.addEventListener('hidden.bs.modal', hiddenEvent);

		modal.show();

		// Fix, because bootstrap removes scroll and add padding on modal opened
		document.body.style.overflow = 'auto'; 
		document.body.style.paddingRight = '0';
	}, [closeCallback]);

    useEffect(() => {
        const getData = async () => {
            setLoading(true);
            let relocations = await ClockingsService.getDispenserRelocationsList(dispenserId, page)
            setLoading(false);

            lastPageRef.current = relocations?.last_page ?? 1;
            setRelocations(prev => ([...prev, ...relocations?.data ?? []]));          
        }
        getData();
    }, [dispenserId, page]);

    useEffect(() => {
        const scrollable = scrollableRef.current;
        if (scrollable) {
            const pageTrigger = scrollable.querySelector("#page-trigger");
            if (pageTrigger) {
                const observer = new IntersectionObserver((entries) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            setPage(prev => {
                                let next = prev + 1;
                                if (lastPageRef.current && next > lastPageRef.current) next = lastPageRef.current;
                                return next;
                            });
                        }
                    });
                }, { root: scrollable, threshold: 1.0 });

                observer.observe(pageTrigger);

                return () => {
                    observer.unobserve(pageTrigger);
                };
            }
        }
    }, []);

	const closeModal = () => {
		// Hide modal
		Modal.getInstance(modalRef.current).hide();
		props.closeCallback();
	}

	return (
		<ModalStyled className="modal" tabIndex="-1" ref={modalRef}>
			<div className="modal-dialog modal-md">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Historial de traspasos</h5>
						<button type="button" className="btn-close" onClick={closeModal}></button>
					</div>
					<div className="modal-body">
                        <div className="scrollable" ref={scrollableRef}>
                            <RelocationsTable 
                                data={relocations}
                            />
                            <div id="page-trigger">&nbsp;</div>
                        </div>
                        { loading && loader }
					</div>
				</div>
			</div>
		</ModalStyled>
	);
}

export default RelocationsHistoryModal;