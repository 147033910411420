import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { default as ClockingsService } from 'services/Clockings';

const Styled = styled.div`
    text-transform: none;
    font-size: 12px;

    .relocations-historial-top {
        width: 100%;

        display: flex;
        justify-content: space-between;

        span {
            
        }

        button {
            width: 100px !important;
            text-align: right;
            color: var(--plenoil-blue);
            font-size: 12px;
        }
    }
`;

export default function RelocationsHistory(props) {
    const dispenserId = props.dispenserId;
    const forceReloadParent = props.forceReload;
    const openModal = props.openModal;

    const [forceReload, setForceReload] = useState(null);
    const [relocations, setRelocations] = useState(null);

    useEffect(() => {
        const getData = async () => {
            let relocations = await ClockingsService.getDispenserRelocationsList(dispenserId, 1)
            setRelocations(relocations);
        }
        getData();

        return () => {
            ClockingsService.cancel();
        }
    }, [dispenserId, forceReload, forceReloadParent]);

    return (
        <Styled id="relocations-historial">
            <div className="relocations-historial-top">
                <span>TRASPASOS</span>
                <button className="btn btn-sm btn-link p-0" onClick={() => openModal()}>HISTORIAL</button>
            </div>
            <RelocationsTable 
                data={relocations?.data?.slice(0, 3)} 
                forceReload={() => setForceReload(Math.random())}
            />
        </Styled>
    );
};

const TableStyled = styled.table`
    thead {

        th {
            background: var(--plenoil-blue-superlight);
        }
    }

    th, td {
        padding: 1px 4px;
        font-weight: normal;

        &:nth-child(1),
        &:nth-child(2) {
            width: 50px;
        }

        &:nth-child(4) {
            width: 100px;
        }

        &:nth-child(5) {
            width: 20px;

            i {
                cursor: pointer;
            }
        }
    }
`;

export function RelocationsTable(props) {
    const forceReloadParent = props.forceReload;
    const dataParent = props.data;

    const [data, setData] = useState([]);

    useEffect(() => {
        setData(dataParent);
    }, [dataParent])


    const deleteRelocation = async (id) => {
        const c = window.confirm('¿Seguro que quieres eliminar este traspaso?');
        if (!c) return;

        let result = await ClockingsService.deleteDispenserRelocation(id);
        if (result.status) {
            let idx = data.findIndex(el => el.id === id);

            let newData = [...data];
            newData.splice(idx, 1);
            setData(newData);

            if (forceReloadParent) {
                forceReloadParent();
            }
        }
    }

    return (
        <TableStyled className="table table-sm table-bordered mb-0">
            <thead>
                <tr>
                    <th>Desde</th>
                    <th>Hasta</th>
                    <th>Estación</th>
                    <th>Turnos</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {data && data?.map((el, idx) => {
                    return (
                        <tr key={idx}>
                            <td>{moment(el.from).format('DD/MM/YY')}</td>
                            <td>{moment(el.to).format('DD/MM/YY')}</td>
                            <td>{el.station?.name}</td>
                            <td>
                                {el?.intervals?.map((el, idx2) => {
                                    let start_at = el.start_at ? moment('2024-11-11 ' + el.start_at).format('HH:mm') : '--:--:--';
                                    let end_at = el.end_at ? moment('2024-11-11 ' + el.end_at).format('HH:mm') : '--:--:--';
        
                                    return (
                                        <React.Fragment key={idx + '-' + idx2}>
                                            <div>Entrada: <span>{start_at}</span></div>
                                            <div>Salida: <span>{end_at}</span></div>
                                        </React.Fragment>
                                    )
                                })}
                            </td>
                            <td>
                                {el.can_delete &&
                                    <i className="bi bi-x text-danger" onClick={() => deleteRelocation(el.id)}></i>
                                }
                            </td>
                        </tr>
                    );
                })}
                {(data && data?.length === 0) && 
                    <tr><td colSpan="100%">No hay traspasos</td></tr>
                }
            </tbody>
        </TableStyled>
    );
}