import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useSearchParams, useNavigate } from "react-router-dom";
import LoggedLayout from '../../layouts/LoggedLayout';
import styled from 'styled-components';
import PageHeader from 'components/PageHeader';
import ThSortable from 'components/ThSortable';
import TrSkeleton from 'components/TrSkeleton';
import DateDropdown from 'components/DateDropdown';
import EmpoweredSelector from 'components/EmpoweredSelector';
import { default as ClockingsService } from 'services/Clockings';
import { default as StationsService } from 'services/Stations';
import { hasCapability, getUser } from 'helpers/user';
import TableAlert from './TableAlert';

const TableResponsive = styled.div` 
	@media (min-width: 992px) {
		overflow-x: visible;
	}
`;

const Table = styled.table`
	tr {
		&:hover td {
			background: var(--bs-gray-100);
		}

		th {
			line-height: 1;
			padding: 10px;
			color: gray;

			&:nth-child(1) {
				width: 80px;
				text-align: center;
			}

			&:nth-child(3) {
				width: 120px;
			}

			&:nth-child(4) {
				width: 120px;
			}

			&:nth-child(5) {
				width: 80px;
			}

			&:nth-child(6),
			&:nth-child(7),
			&:nth-child(8),
			&:nth-child(9) {
				width: 80px;
				text-align: center;
				color: gray;
			}

			
			&:nth-child(10) {
				width: 40px;
				text-align: center;
			}

			&.active {
				
			}
		}

		td {
			line-height: 1;
			padding: 10px;
			cursor: pointer;

			&:nth-child(1) {
				font-size: 12px;
				color: gray;
				text-align: center;
			}

			&:nth-child(2) {
				font-weight: bold;
				color: #333;
			}

			&:nth-child(3) {
				font-weight: bold;
				width: 120px;
				color: #333;
			}

			&:nth-child(4) {
				width: 140px;
				font-size: 12px;
				color: gray;
			}

			&:nth-child(5) {
				width: 80px;
				color: gray;
				text-align: center;
				letter-spacing: -1px;

				div {
					white-space: nowrap;

					&:not(:last-of-type) {
						margin-bottom: 6px;
					}
				}
			}

			&:nth-child(6),
			&:nth-child(7),
			&:nth-child(8),
			&:nth-child(9) {
				width: 80px;
				text-align: center;
			}

			
			&:nth-child(10) {
				width: 40px;
				text-align: center;
			}
		}
	}
`;


const DateDropDownWrapper = styled.div` 
	padding: 0;
	min-height: 0;
	width: 192px;
	display: inline-block;

	& > .date-dropdown {
		display: block;

		& > .dropdown {
			width: 100%;
			display: block !important;

			& > .btn {
				padding: 0;
				width: 100% !important;
				display: flex;
				align-items: center;
				padding: 0 5px;

				small {
					margin-left: auto;
				}
			}
		}
	}
`;


const EmpoweredSelectorWrapper = styled.div` 
	margin-right: 10px;

	.empowered-selector {
		width: 200px;
		
		.empowered-selector_label {
			height: 28px;
			white-space: nowrap;
			overflow: hidden;

			&:hover {
				background: white;
			}

			.remove-icon {
				background: white;
				border-radius: 50%;
			}
		}
	}
`;

export default function Dispensers(props) {
	const [queryParams] = useSearchParams();
	const navigate = useNavigate();

	const responsible_id = queryParams.get('responsible_id');

	let [loading, setLoading] = useState(true);
	let [dispensers, setDispensers] = useState([]);
	let [delaysCount, setDelaysCount] = useState(0);
	let [noRecordsCount, setNoRecordsCount] = useState(0);
	let [responsibles, setResponsibles] = useState([]);
	let [date, setDate] = useState(moment().format('YYYY-MM-DD'));
	let [station, setStation] = useState(null);
	let [responsible, setResponsible] = useState({
		id: responsible_id,
		name: null
	});
	let [dispenser, setDispenser] = useState(null);
	let [sortDirection, setSortDirection] = useState('asc');
	let [sortField, setSortField] = useState('name');
	let [skeletonRows, setSkeletonRows] = useState(5);

	const sortTableClick = (field) => {
		if ( !field ) return;
		if ( field === sortField ) setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		setSortField(field);
	};	

	const loadStations = async (input, callback) => {
		let result = await StationsService.getList({
			search: input,
			no_paginate: true
		});

		let formatted = result.map((el) => {
			return {
				value: el, 
				label: el.name
			};
		});

		callback(formatted);
	}

	const loadResponsibles = async (input, callback, args) => {
		let result = await ClockingsService.getResponsibles({
			no_paginate: true,
			search: input,
			station_id: args.station_id
		});

		let formatted = result.map((el) => {
			return {
				value: el, 
				label: el.name
			};
		});

		callback(formatted);
	}

	const loadDispensers = async (input, callback, args) => {
		let result = await ClockingsService.getDispensers({
			search: input,
			// station_id: args.stationId
		});

		let formatted = result.map((el) => {
			return {
				value: el, 
				label: el.first_name + ' ' + el.last_name
			};
		});

		callback(formatted);
	}

	useEffect(() => {
		// Get responsibles only if clocking primary
		if ( hasCapability('clockings_primary') ) {
			let getResponsibles = async () => {
				let responsibles = await ClockingsService.getResponsibles({
					no_paginate: true,
					sort: 'name',
					direction: 'asc',
					station_id: station?.id,
				});
				setResponsibles(responsibles);
			}
			getResponsibles();
		} else { // Clocking secondary
			setResponsibles([getUser()]);
		}

		// Get dispensers
		let getDispensersSchedules = async () => {
			setLoading(true);
			setDispensers([]);
			let dispensers = await ClockingsService.getDispensersSchedules({
				sort: sortField,
				direction: sortDirection,
				date: date,
				station_id: station?.id,
				employee_id: dispenser?.id
			});
			setLoading(false);
			setSkeletonRows(dispensers?.length >= 5 ? dispensers.length : 5);
			setDispensers(dispensers);

			if ( dispensers ) {
				let delays = 0;
				let noRecords = 0;
				dispensers.forEach(d => {
					d.intervals.forEach(i => {
						if ( i.alerts.isLateEnter ) {
							delays++;
						}
						if ( i.alerts.notEntered ) {
							noRecords++;
						}
					});
				});
				setDelaysCount(delays);
				setNoRecordsCount(noRecords);
			}
		}
		getDispensersSchedules();
	}, [sortField, sortDirection, station?.id, dispenser?.id, date]);

	useEffect(() => {
		if ( responsible_id ) {
			let loadResponsible = async () => {
				let responsible = await ClockingsService.getResponsible(responsible_id);
				setResponsible(responsible);
			}
			loadResponsible();
		}
	}, [responsible_id]);

	useEffect(() => {
		// Fix to hide empty responsibles wrapper
		const elements = document.querySelectorAll('[id^="responsible-wrapper-"]');
		elements.forEach((el) => {
			if (el.querySelector('tbody tr')) {
				const rowCount = el.querySelectorAll('tbody tr:not(.empty-tr)').length;
				if (rowCount === 0) {
					el.style.display = 'none';
				} else {
					el.style.display = 'block';
				}
			}
		});
	}, [dispensers]);

	return (
		<LoggedLayout>
			<div className="col-md-12 mb-3">
				<PageHeader style={{overflowX: 'visible', overflowY: 'visible'}}>
					<span className="header-column header-title">Control de turnos</span>

					<span className="header-column">
						<div className="header-column-text">
							TARDANZAS&nbsp;
						</div>
						<div className="header-column-number" style={{color: 'var(--plenoil-red)'}}>
							{delaysCount ?? '-'}
						</div>
					</span>

					<span className="header-column">
						<div className="header-column-text">
							NO REGISTRADAS&nbsp;
						</div>
						<div className="header-column-number" style={{color: 'var(--plenoil-red)'}}>
							{noRecordsCount ?? '-'}
						</div>
					</span>

					<span className="header-column header-icon-end">
						{/* <i className="bi bi-printer text-plenoil-purple"></i> */}
					</span>
				</PageHeader>
			</div>

			<div className="col-md-12 mb-3">
				<div className="bg-plenoil-purple text-white">
					<div className="row justify-content-end py-2 px-3">
						<div className="col-md-2">
							<DateDropDownWrapper className="form-control form-control-sm">
								<DateDropdown 
									defaultPeriod="custom"
									onChange={(period, range) => setDate(range.from)}
									onlyDates={true}
									mode="single"
									showPeriods={false}
									startRange={{from: date, to: date}}
								/>
							</DateDropDownWrapper>
						</div>
						<div className="col-md-2">
							<EmpoweredSelectorWrapper>
								<EmpoweredSelector
									load={(input, callback) => loadStations(input, callback)}
									onChange={(value, text) => setStation(value)}
									timeout={200}
									label={station?.name}
									value={station?.id ?? null}
									placeholder="Estación"
								/>
							</EmpoweredSelectorWrapper>
						</div>
						{ hasCapability('clockings_primary') &&
							<div className="col-md-2">
								<EmpoweredSelectorWrapper>
									<EmpoweredSelector
										load={(input, callback, args) => loadResponsibles(input, callback, args)}
										args={{station_id: station?.id}}
										onChange={(value, text) => setResponsible(value)}
										timeout={200}
										label={responsible?.name}
										value={responsible?.id ?? null}
										placeholder="Responsable"
									/>
								</EmpoweredSelectorWrapper>
							</div>
						}
						<div className="col-md-2">
							<EmpoweredSelectorWrapper>
								<EmpoweredSelector
									load={(input, callback, args) => loadDispensers(input, callback, args)}
									args={{stationId: station?.id}}
									onChange={(value, text) => setDispenser(value)}
									timeout={200}
									label={dispenser ? dispenser.first_name + ' ' + dispenser.last_name : ''}
									value={dispenser?.id ?? null}
									placeholder="Expendedor"
								/>
							</EmpoweredSelectorWrapper>
						</div>
					</div>
				</div>
			</div>

			<div className="col-md-12 mb-3">
				{ responsibles.filter(el => {
					if ( hasCapability('clockings_primary') && responsible?.id ) {
						return el.id === responsible.id;
					}
					return true;
				}).map((rEl, rIdx) => {
					let showEmpty = true;

					return (
						<div className="mb-5" key={'r' + rIdx} id={'responsible-wrapper-' + rEl.id}>
							<h2>{rEl.name}</h2>

							<TableResponsive className="table-responsive shadow-sm rounded">
								<Table className="table table-sm table-bordered table-custom bg-white mb-0">
									<thead>
										<tr>
											<th>Día</th>
											<ThSortable direction={sortDirection} active={sortField === 'name'} onClick={() => sortTableClick('name')}>Expendedor</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'station_name'} onClick={() => sortTableClick('station_name')}>Estación</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'schedule_name'} onClick={() => sortTableClick('schedule_name')}>Turno</ThSortable>
											<th>Horario esperado</th>
											<th>Entrada</th>
											<th>Salida</th>
											<th>Entrada</th>
											<th>Salida</th>
											<th></th>
										</tr>
									</thead>
									<tbody>
										{ dispensers?.filter(el => {
											// If capability clockings_primary then show only the responsible's dispensers
											if ( hasCapability('clockings_primary') ) {
												return el.head_employees?.find(he => he.id === rEl.id) ? true : false;
											}
											return true;
										}).map(el => {
											showEmpty = false;

											return (
												<React.Fragment key={'r' + rEl.id + '-e' + el.employee_id}>
													<tr onClick={() => navigate('/clockings/dispenser/' + el.employee_id + '?schedule_day=' + el.date)}>
														<td>{moment(el.date).format('DD-MM-YYYY')}</td>
														<td>{el.employee_name}</td>
														<td>{el.station_name}</td>
														<td>{el.schedule_name}</td>
														<td>
															{el.intervals &&
																el.intervals.map((i, iIdx) => {
																	return (
																		<div key={'r' + rEl.id + '-e' + el.id + '-in' + iIdx}>{moment('2024-11-07 ' + i.start_at).format('HH:mm')} / {moment('2024-11-07 ' + i.end_at).format('HH:mm')}</div>
																	);
																})
															}
														</td>
														<td><TableAlert interval={el.intervals[0]} what={'start_at'} /></td>
														<td><TableAlert interval={el.intervals[0]} what={'end_at'} /></td>
														<td><TableAlert interval={el.intervals[1]} what={'start_at'} /></td>
														<td><TableAlert interval={el.intervals[1]} what={'end_at'} /></td>
														<td></td>
													</tr>
												</React.Fragment>
											)
										}) }

										{ (showEmpty && !loading) && <tr className="empty-tr"><td colSpan="100%">No hay datos disponibles</td></tr> }

										{ loading && <TrSkeleton rows={skeletonRows} columns={10} /> }
									</tbody>
								</Table>
							</TableResponsive>
						</div>
					);
				})}	
			</div>
		</LoggedLayout>
	);
}