import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { default as ClockingsService } from 'services/Clockings';
import moment from 'moment';
import { assets } from 'helpers/generic';
import PinModal from './PinModal';
import NewDestination from './NewDestination';
import RelocationsHistory from './RelocationsHistory';
import RelocationsHistoryModal from './RelocationsHistoryModal';

const Styled = styled.div`
    .title {
        text-transform: uppercase;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid var(--plenoil-gray);
        
        .dispenser_name {
            font-size: 20px;
            color: var(--plenoil-gray);
            margin-left: 20px;
        }

        .date {
            margin-right: auto;
            font-size: 13px;
            margin-left: 15px;
            color: black;
        }

        .pin_wrapper {
            margin-right: 20px;
        }

        .responsible_name {
            color: var(--plenoil-gray);
            font-size: 13px;

            span {
                color: black;
            }
        }
    }

    .schedule {
        padding: 10px 0;
        display: flex;
        text-transform: uppercase;
        font-size: 14px;

        .column1 {
            border-right: 1px solid var(--plenoil-gray);
            padding-right: 10px;
            width: 350px;
            min-width: 350px;
            max-width: 350px;

            display: flex;

            .clock {
                width: 80px;
                aspect-ratio: 1;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .text {
                padding-left: 10px;
                color: var(--plenoil-gray);

                span {
                    color: black;
                }

                .schedule-intervals {
                    margin-top: 3px;
                    line-height: 100%;
                }
            }
        }

        .column2 {
            border-right: 1px solid var(--plenoil-gray);
            padding: 0px 10px;
            min-width: 200px;
            max-width: 200px;
            width: 200px;

            color: var(--plenoil-gray);

            span {
                color: black;
            }
        }

        .column3 {
            border-right: 1px solid var(--plenoil-gray);
            padding: 0px 10px;
            width: 100px;
            min-width: 100px;
            max-width: 100px;
            display: flex;
            align-items: center;
            justify-content: center;

            i.red {
                color: var(--plenoil-red);
                transform: rotate(10deg) scale(1.5);
            }
        }

        .column4 {
            width: 100%;
            padding-left: 15px;
            padding-right: 15px;

            button {
                width: 150px;
            }
        }
    }

    .next-schedule {
        border-top: 1px solid var(--plenoil-gray);
        border-bottom: 1px solid var(--plenoil-gray);
        color: var(--plenoil-gray);
        font-size: 13px;
        padding: 6px 15px;
        text-transform: uppercase;

        span {
            color: black;
        }
    }
`;

export default function TopSection(props) {
    const { dispenser, date } = props;

    const [schedule, setSchedule] = useState(null);
    const [nextSchedule, setNextSchedule] = useState(null);
    const [pinModalOpened, setPinModalOpened] = useState(false);
    const [relocationsHistoryModalOpened, setRelocationsHistoryModalOpened] = useState(false);
    const [newDestinationOpened, setNewDestinationOpened] = useState(false);
    const [forceReloadRelocationsHistory, setForceReloadRelocationsHistory] = useState(false);

	useEffect(() => {
        if ( !dispenser?.id || !date ) return;

		let getCurrentSchedule = async () => {
			let schedule = await ClockingsService.getDispenserSchedule(dispenser?.id, date);
			setSchedule({...schedule});
		}
		getCurrentSchedule();

        let getNextSchedule = async () => {
            let nextScheduleDate = await ClockingsService.getDispenserNextScheduleDate(dispenser?.id, date);
            if ( nextScheduleDate?.date ) {
                let nextSchedule = await ClockingsService.getDispenserSchedule(dispenser?.id, nextScheduleDate?.date);
                setNextSchedule({...nextSchedule});
            }
        }   
        getNextSchedule();
	}, [dispenser?.id, date]);

	return (
        <>
            <Styled>
                <div className="title">
                    <div className="dispenser_name">
                        {dispenser?.full_name}
                    </div>
                    <div className="date">
                        {date ? moment(date).format('DD/MM/YYYY') : ''}
                    </div>
                    {!newDestinationOpened &&
                        <button className="btn btn-sm btn-plenoil-primary py-0 me-3" onClick={() => setNewDestinationOpened(true)}><i className="bi bi-person"></i> NUEVO DESTINO</button>
                    }
                    <div className="pin_wrapper">
                        <button className="btn btn-light btn-sm py-0 text-secondary d-flex align-items-center" onClick={() => setPinModalOpened(true)}><i className="bi bi-key-fill me-2"></i> APP PIN</button>
                    </div>
                    <div className="responsible_name">
                        Responsable: <span>{dispenser?.responsibles?.map(el => el.name)?.join(', ')}</span>
                    </div>
                </div>
                {newDestinationOpened &&
                    <NewDestination 
                        dispenser={dispenser}
                        onCancel={() => {
                            setNewDestinationOpened(false);
                            setForceReloadRelocationsHistory(Math.random());
                        }}
                    />
                }
                
                <div className="schedule">
                    <div className="column1">
                        <div className="clock">
                            <img src={assets('/img/reloj.svg')} />
                        </div>

                        <div className="text">
                            <div>Estación: <span>{schedule?.station_name}</span></div>
                            <div>Turno: <span>{schedule && schedule.name ? schedule.name : '-'}</span></div>
                            <div className="schedule-intervals">
                                Horario:&nbsp;
                                <span>
                                    {!schedule || (schedule?.intervals?.length === 0) ? '-' : ''}
                                    {schedule?.intervals?.map(el => moment('2024-11-11 ' + el.start_at).format('HH:mm') + ' / ' + moment('2024-11-11 ' + el.end_at).format('HH:mm'))?.join(' - ')}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="column2">
                        {schedule?.intervals?.map((el, index) => {
                            let chronometer_start_at = el.chronometer_start_at ? moment('2024-11-11 ' + el.chronometer_start_at).format('HH:mm') : '--:--:--';
                            let chronometer_end_at = el.chronometer_end_at ? moment('2024-11-11 ' + el.chronometer_end_at).format('HH:mm') : '--:--:--';

                            return (
                                <React.Fragment key={index}>
                                    <div>Entrada: <span>{chronometer_start_at}</span></div>
                                    <div>Salida: <span>{chronometer_end_at}</span></div>
                                </React.Fragment>
                            )
                        })}
                    </div>
                    <div className="column3">
                        {schedule?.with_alert ?
                            <i className="bi bi-bell-fill red parpadeo"></i>
                            :
                            <i className="bi bi-bell"></i>
                        }   
                    </div>
                    <div className="column4">
                        {dispenser?.id &&
                            <RelocationsHistory 
                                dispenserId={dispenser?.id}
                                forceReload={forceReloadRelocationsHistory}
                                openModal={() => setRelocationsHistoryModalOpened(true)}
                            />
                        }
                    </div>
                </div>
                <div className="next-schedule">
                    <div className="row">
                        <div className="col-md-3">
                            Próximo turno: <span>{nextSchedule ? moment(nextSchedule.date).format('DD-MM-YYYY') : '-'}</span>
                        </div>
                        <div className="col-md-3">
                            Estación: <span>{nextSchedule ? nextSchedule.station_name : '-'}</span>
                        </div>
                        <div className="col-md-2">
                            Turno: <span>{nextSchedule ? nextSchedule.name : '-'}</span>
                        </div>
                        <div className="col-md-3">
                            Horario:&nbsp;
                            {!nextSchedule || (nextSchedule?.intervals?.length === 0) ? '-' : ''}
                            <span>{nextSchedule?.intervals?.map(el => moment('2024-11-11 ' + el.start_at).format('HH:mm') + ' / ' + moment('2024-11-11 ' + el.end_at).format('HH:mm'))?.join(' - ')}</span>
                        </div>
                    </div>
                </div>
            </Styled>
            {pinModalOpened &&
                <PinModal 
                    dispenser={dispenser}
                    closeCallback={() => setPinModalOpened(false)}
                />
            }
            {relocationsHistoryModalOpened &&
                <RelocationsHistoryModal 
                    dispenser={dispenser}
                    closeCallback={() => setRelocationsHistoryModalOpened(false)}
                />
            }
        </>
	);
}