import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import 'moment/locale/es';
import ThSortable from 'components/ThSortable';
import TrSkeleton from 'components/TrSkeleton';
import Resume from './Resume';
import TableAlert from '../../TableAlert';
import { default as ClockingsService } from 'services/Clockings';

moment.locale('es');

const Styled = styled.div`

    .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-transform: uppercase;

        h3 {
            font-size: 20px;
            margin: 0;
        }

        .filters {
            
            select {
                display: inline-block;
                width: 200px;
            }
        }
    }

    .table-wrapper {
        margin-top: 10px;

        table {

            thead {

                tr {

                    th {
                        font-size: 12px;
                        background: var(--plenoil-blue);
                        color: white;
                        font-weight: 400;
                        text-transform: uppercase;
                        padding: 5px 10px;
                        white-space: nowrap;
                        text-align: center;

                        &:nth-child(2) {
                            text-align: left;
                            width: 200px;
                        }

                        &.active {
                            color: white !important;
                        }

                        &:last-of-type {
                            width: 300px;
                        }
                    }
                }
            }

            tbody {

                tr {

                    td {
                        color: var(--plenoil-gray);
                        font-size: 12px;

                        &:nth-child(2) {
                            
                            b {
                                color: black;
                            }

                            .intervals {
                                white-space: nowrap;

                                span {
                                    letter-spacing: -1px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default function MonthsTable(props) {
    const { dispenser } = props;

	const [loading, setLoading] = useState(true);
    const [sortDirection, setSortDirection] = useState('desc');
	const [sortField, setSortField] = useState('date');
	const [skeletonRows, setSkeletonRows] = useState(5);
	const [schedules, setSchedules] = useState([]);
    const [month, setMonth] = useState(moment().format('MM'));
    const [year, setYear] = useState(moment().format('YYYY'));
    
    const years = [moment().subtract(1, 'years').format('YYYY'), moment().format('YYYY')];
	
	useEffect(() => {
		if ( !dispenser?.id ) return;

		let getSchedules = async () => {
			setLoading(true);
			setSchedules([]);

            let date = year + '-' + month + '-01';
            let dateFrom = moment(date).startOf('month').format('YYYY-MM-DD');
            let dateTo = moment(date).endOf('month').format('YYYY-MM-DD');

			let schedules = await ClockingsService.getDispenserSchedules(dispenser?.id, dateFrom, dateTo, {
				sort: sortField,
				direction: sortDirection
			});

			setLoading(false);
			setSkeletonRows(schedules?.length >= 5 ? schedules.length : 5);
			setSchedules(schedules);
		}
		getSchedules();
	}, [dispenser?.id, year, month, sortField, sortDirection]);

    const sortTableClick = (field) => {
		if ( !field ) return;
		if ( field === sortField ) setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		setSortField(field);
	};	

    const setYearMonth = (value) => {
        let [y, m] = value.split('-');
        setYear(y);
        setMonth(m);
    }

    let monthName = moment(year + '-' + (month.length < 2 ? '0' + month : month) + '-01').format('MMMM');
    let yearShort = moment(year + '-01-01').format('YY');

    let yearMonth = year + '-' + month;

	return (
		<Styled>
            <div className="top">
			    <h3>{monthName} / {yearShort}</h3>
                <div className="filters">
                    <select className="form-control form-control-sm" value={yearMonth} onChange={(e) => setYearMonth(e.target.value)}>
                        { years.reverse().map(year => {
                            return [...Array(12).keys()].reverse().map(month => {
                                month += 1; // Fix 0 based month

                                let yearMonth = year + '-' + (month < 10 ? '0' + month : month);

                                return (
                                    <option key={yearMonth} value={yearMonth}>
                                        {moment(yearMonth + '-01').format('MMMM')} / {moment(year + '-01-01').format('YY')}
                                    </option>
                                );
                            })
                        }) }
                    </select>
                    <button className="btn btn-sm btn-link ms-2"><i className="bi bi-download"></i></button>
                </div>
            </div>
            <div className="table-wrapper">
                <div className="table-responsive">
                    <table className="table table-bordered table-sm">
                        <thead>
                            <tr>
                                <ThSortable direction={sortDirection} active={sortField === 'date'} onClick={() => sortTableClick('date')}>Día</ThSortable>
                                <ThSortable direction={sortDirection} active={sortField === 'station_name'} onClick={() => sortTableClick('station_name')}>Estación</ThSortable>
                                <th>Entrada</th>
                                <th>Salida</th>
                                <th>Entrada</th>
                                <th>Salida</th>
                                <ThSortable direction={sortDirection} active={sortField === 'chronometers_total_in_seconds'} onClick={() => sortTableClick('chronometers_total_in_seconds')}>Registradas</ThSortable>
                                <ThSortable direction={sortDirection} active={sortField === 'balance_in_seconds'} onClick={() => sortTableClick('balance_in_seconds')}>Balance</ThSortable>
                                <th>N. Destino</th>
                                <th>Motivo</th>
                                <th>Notas</th>
                                <th>Resumen</th>
                            </tr>
                        </thead>
                        <tbody>
                            { schedules?.map((el, idx) => { 
                                return (
                                    <React.Fragment key={'s' + idx}>
                                        <tr>
                                            <td>{moment(el.date).format('DD-MM-YYYY')}</td>
                                            <td>
                                                <div><b>{el.station_name}</b></div>
                                                <div>{el.schedule_name}</div>
                                                <div className="intervals">
                                                    {el.intervals &&
                                                        <>
                                                            HORARIO:&nbsp;
                                                            {el.intervals.map((i, iIdx) => {
                                                                return (
                                                                    <span key={'s' + idx + '-in' + iIdx}>
                                                                        {moment('2024-11-07 ' + i.start_at).format('HH:mm')} - {moment('2024-11-07 ' + i.end_at).format('HH:mm')}
                                                                        {iIdx < el.intervals.length - 1 && <>&nbsp;/&nbsp;</>}
                                                                    </span>
                                                                );
                                                            })}
                                                        </>
                                                    }
                                                </div>
                                            </td>
                                            <td><TableAlert interval={el.intervals[0]} what={'start_at'} /></td>
                                            <td><TableAlert interval={el.intervals[0]} what={'end_at'} /></td>
                                            <td><TableAlert interval={el.intervals[1]} what={'start_at'} /></td>
                                            <td><TableAlert interval={el.intervals[1]} what={'end_at'} /></td>
                                            <td>{el.chronometers_total !== 0 ? el.chronometers_total : ''}</td>
                                            <td>{el.balance !== 0 ? el.balance : ''}</td>
                                            <td>{el.type === 'relocation' ? el.station_name : ''}</td>
                                            <td>{el.reason ?? ''}</td>
                                            <td>{el.notes ?? ''}</td>
                                            {idx === 0 && <Resume schedules={schedules} />}
                                        </tr>
                                    </React.Fragment>
                                )
                            }) }

                            { (!schedules.length && !loading) && <tr><td colSpan="100%">No hay datos disponibles</td></tr> }

                            { loading && <TrSkeleton rows={skeletonRows} columns={13} /> }
                        </tbody>
                    </table>
                </div>
            </div>
		</Styled>
	);
}